import React from 'react'
import tooth from "../../assets/Loader/toothLoader.png";
import errorIcon from "../../assets/ErrorIcons/error.png";

import { useSelector, useDispatch } from 'react-redux';
import { retakePhoto } from '../../Store/sim';
import Modal from './Modal';

export const UploadProgress = () => {
  const dispatch = useDispatch();
  const uploadPercent = useSelector(state => state.sim.uploadPercent);
  const processingPercent = useSelector(state => state.sim.processingPercent);
  const apiError = useSelector(state => state.sim.apiError);
  const apiStatus = useSelector(state => state.sim.apiStatus);

  const percent = uploadPercent === 100 ? processingPercent : uploadPercent;

  return (
    <>
      {apiStatus === 'failed' && (
        <Modal isOpen={true} handleClose={() => dispatch(retakePhoto())}>
          <div className="flex flex-col items-center pt-2 pb-6 text-center" style={{ fontFamily: "Poppins" }}>
            <div className="relative mb-4">
              <img src={errorIcon} alt="back buttton" className="" />
            </div>

            <h2 className="mb-2 text-xl font-normal text-red-500">
              Error!
            </h2>

            <p className="mb-6 text-sm text-gray-600">
              {apiError ? apiError : "AI is currently down and please proceed the scan later."}
            </p>

            <button
              className="w-full py-2 px-4 bg-[#1B1B1B] hover:bg-[#2F2F2F] text-white rounded transition duration-200"
              onClick={() => dispatch(retakePhoto())}
            >
              Retake
            </button>
          </div>
        </Modal>
      )}
      <div className="flex flex-col items-center justify-center min-h-screen bg-white">
        <div className="relative w-32 h-32 mb-8">
          <div className="absolute inset-0 flex items-center justify-center">
            <img src={tooth} alt="back buttton" className="" />
          </div>
        </div>
        <div className="text-4xl font-bold text-gray-800 mb-4">{percent}%</div>
        <div className="w-64 h-2 bg-gray-200 rounded-full mb-4">
          <div
            className="h-full bg-green-400 rounded-full"
            style={{ width: `${percent}%` }}
          ></div>
        </div>
        <div className="text-lg text-gray-600"><span className='capitalize'>{apiStatus}</span> your image...</div>
      </div>
    </>
  )
}