import React, { useState, useRef, useEffect } from 'react';

export default function Dropdown({ 
    buttonText = 'Dropdown', 
    items = ['Item 1', 'Item 2', 'Item 3'],
    btnClasses = "text-base font-red-hat h-12 rounded-lg  w-full  text-white universal-button  sm600:text-2xl",
    onItemClick = (item) => null 
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mt-2 w-full mb-2 text-center" ref={dropdownRef}>
      <button
        type="button"
        className={btnClasses}
        onClick={toggleDropdown}
        aria-expanded={isOpen}
      >
        {buttonText}
      </button>
      {isOpen && (
        <div className="rounded-lg z-10 absolute left-10 w-56 mt-1 origin-top-right universal-bg ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {items.map((item, index) => (
              <div key={index}>
                <div
                  className="block px-1 py-1 text-sm text-white"
                  role="menuitem"
                  onClick={() => {
                    setIsOpen(false);
                    onItemClick(item);
                  }}
                >
                  {item}
                </div>
                {index < items.length - 1 && <hr className="my-1 border-gray-300" />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
