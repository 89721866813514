import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearImages, uploadFile } from '../../Store/sim'

export const PhotoPreview = () => {
  const takenPhoto = useSelector(state => state.sim.takenPhoto);
  const dispatch = useDispatch()
  const handleContinue = async () => {
    const response = await fetch(takenPhoto);
    const blob = await response.blob();
    dispatch(uploadFile(blob))
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <h1 className="text-2xl font-semibold mb-6">Preview</h1>
      {/* <div className=" h-1/2 py-4 px-4">
          <img
            src={takenPhoto}
            alt="ai-teeth"
            className="max-h-96 w-4/5 h-4/5 w-auto object-contain rounded-lg"
          />
        </div> */}
      <div className="relative w-64 aspect-[3/4] mb-6">
        <div className="absolute inset-0 bg-lime-300 rounded-[50%] transform scale-[1.03]"></div>
        <div className="absolute inset-0 overflow-hidden rounded-[50%]">
          <img
            src={takenPhoto}
            width={256}
            height={320}
            alt="Preview"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="w-full max-w-xs space-y-4">
        <button onClick={handleContinue} className="w-80 rounded-lg universal-button text-white font-red-hat font-bold text-base py-3 px-2 shadow-2xl sm600:text-2xl">
          Continue
        </button>
        <button onClick={() => dispatch(clearImages())} variant="outline" className="w-80 rounded-lg font-red-hat bg-slate-300 font-bold text-gray-950 py-3 px-4 shadow-2xl sm600:text-2xl">
          Retake
        </button>
      </div>
    </div>
  )
}
