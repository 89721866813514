import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateRandomAlphaNumeric } from "../Utils/alphaNumeric";
import axios from "axios";
// const API_URL = "http://86.38.238.36:5550/"
const API_URL = "https://simapi.mithilait.com/";
// const API_URL = "https://toothlensim.com/api/"

export const delay = (ms = 1000) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

// This thunk will handle the interval logic
export const startInterval = createAsyncThunk(
    "interval/start",
    async (_, { dispatch, getState }) => {
        let tempCount = 0;
        const intervalId = setInterval(() => {
            tempCount = Math.min(tempCount + 1, 99);
            dispatch(updateProcessingPercent(tempCount));
        }, 700);

        // Return the intervalId so we can clear it later if needed
        return intervalId;
    }
);

export const stopInterval = createAsyncThunk(
    "interval/stop",
    async (intervalId, { dispatch, getState }) => {
        clearInterval(intervalId);
        return intervalId;
    }
);

// Define an async thunk
export const submitReview = createAsyncThunk(
    'sim/submitReview',
    async (data, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        try {
            const response = await axios.post(
                API_URL + "feedback",
                {
                    rating: state?.sim?.rating,
                    feedback: state?.sim?.feedback || "",
                    file_path: state?.sim?.filePath
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            dispatch(onSubmitReview())
            await delay(4000)
            dispatch(closeReview())
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Upload failed");
        }
    }
);

const loadImage = (url, cb = () => null) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {

            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            let base64String = "";

            try {
                // Get the Base64 string
                base64String = canvas.toDataURL("image/png");
                console.log("base64String", 'loaded');
                // resolve(base64String);
            } catch (error) {
                reject(new Error("Failed to convert image to Base64"));
            }

            resolve(image);
            cb(url, base64String);
        };
        image.onerror = (error) => {
            cb(url, "", error);
            resolve(error);
        };
        image.crossOrigin = "Anonymous"; // Allow cross-origin image processing
        image.src = url;
    });
};

// Async thunk for file upload
export const uploadFile = createAsyncThunk(
    "sim/upload",
    async (file, thunkAPI) => {
        try {
            let intervalId = 0;
            const formData = new FormData();
            formData.append("file", file, "report_image.png");

            const response = await axios.post(API_URL + `generate`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: async (progressEvent) => {
                    // Calculate upload percentage
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    thunkAPI.dispatch(updatePercent(progress));
                    if (progress === 100) {
                        const iData = await thunkAPI.dispatch(startInterval());
                        intervalId = iData.payload;
                    }
                },
            });
            thunkAPI.dispatch(stopInterval(intervalId));
            const state = thunkAPI.getState()
            const result = response.data;
            const { file_path, outputpath } = response.data;
            if (file_path && outputpath) {
                thunkAPI.dispatch(cachedImage({ key: 'takenPhoto', base64String: state?.sim?.takenPhoto }))
                await loadImage(API_URL + outputpath, (url, base64String, error) => {
                    if (!error) {
                        thunkAPI.dispatch(cachedImage({ key: 'fullOutputPath', base64String }))
                    }
                    thunkAPI.dispatch(
                        regenerate({
                            endPoint: "teeth_whitening",
                            filePath: result.file_path,
                        })
                    );
                });
            }

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || "Upload failed");
        }
    }
);

export const regenerate = createAsyncThunk(
    "sim/regenerate",
    // endPoint - regenerate or teeth_whitening
    async ({ filePath, reason, endPoint = "regenerate" }, thunkAPI) => {
        try {
            let intervalId = 0;
            const iData = await thunkAPI.dispatch(startInterval());
            intervalId = iData.payload;
            const response = await axios.post(
                API_URL + endPoint,
                {
                    file_path: filePath,
                    reason,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const result = {
                filePath,
                reason,
                endPoint: endPoint ? endPoint : "regenerate",
                ...response.data,
            };
            await loadImage(API_URL + response.data.output_path, (url, base64String, error) => {
                if (!error) {
                    if (endPoint === 'teeth_whitening') {
                        thunkAPI.dispatch(cachedImage({ key: 'fullTeethWhitening', base64String }))
                    } else {
                        thunkAPI.dispatch(cachedImage({ key: 'fullOutputPath', base64String }))
                    }
                }
                thunkAPI.dispatch(stopInterval(intervalId));
            });
            // thunkAPI.dispatch(stopInterval(intervalId));
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || "Upload failed");
        }
    }
);

// pdf

const downloadPDF = async (url, fileName) => {
    return fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.blob();
        })
        .then((blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
            return blob;
        })
        .catch((error) => console.error("Error downloading the PDF:", error));
};

export const generatePdf = createAsyncThunk(
    "sim/generatePdf",
    // filePaths = []
    async ({ filePaths }, thunkAPI) => {
        try {
            const response = await axios.post(
                API_URL + "pdf",
                {
                    file_paths: filePaths,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const rData = response.data;

            if (rData.pdf_path) {
                await downloadPDF(API_URL + rData.pdf_path, "sim.pdf");
            }
            const result = {
                filePaths,
                ...response.data,
            };
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || "Upload failed");
        }
    }
);

const initialState = {
    takenPhoto: null,
    filePath: null, // get from API after upload this original photo
    uploadPercent: 0,
    isLoading: false,
    apiError: undefined,
    apiStatus: "",
    isUploaded: false,
    isProcessed: false,
    state: "LandingScreen", // "LandingScreen", "DemoScreen", "TakePhoto", "PreviewPhoto", "UploadPhoto", "ResultPhoto"
    teethWhitening: null,
    fullTeethWhitening: null,
    outputPath: null,
    fullOutputPath: null,
    processingPercent: 0,
    intervalId: null,
    pdfDownloading: false,
    pdfUrl: null,
    pdfFullUrl: null,
    isShowReview: false,
    rating: 0,
    feedback: "",
    isSubmittedReview: false,
    cachedImages: {
        fullTeethWhitening: "",
        fullOutputPath: "",
        takenPhoto: ""
    }
};

const sim = createSlice({
    name: "sim",
    initialState,
    reducers: {
        clearImages: () => {
            return initialState;
        },
        gotoDemoScreen: (state) => {
            state.state = "DemoScreen";
        },
        retakePhoto: (state) => {
            state.state = "DemoScreen";
        },
        addImages: (state, action) => {
            state.takenPhoto = action.payload;
            state.state = "PreviewPhoto";
        },
        startUploading: (state) => {
            state.isLoading = true;
            state.state = "UploadPhoto";
        },
        updatePercent: (state, action) => {
            state.uploadPercent = action.payload;
            if (action.payload === 100) {
                state.apiStatus = "processing";
            }
        },
        updateProcessingPercent: (state, action) => {
            state.processingPercent = action.payload;
        },
        createFullScanS3Folder: (state) => {
            const randomString = generateRandomAlphaNumeric(2);
            const timestamp = Date.now().toString();
            const randomString2 = generateRandomAlphaNumeric(1);
            state.fullScanS3Folder = randomString + timestamp + randomString2;
        },
        clearFullScanfolder: (state) => {
            state.fullScanS3Folder = "";
        },
        openReview: (state) => {
            // if (!state.isSubmittedReview) {
                state.isSubmittedReview = false;
                state.isShowReview = true;
                state.rating = 0;
                state.feedback = "";
            // }
        },
        closeReview: (state) => {
            state.isShowReview = false;
        },
        changeRating: (state, action) => {
            state.rating = Number(action.payload)
        },
        changeFeedback: (state, action) => {
            state.feedback = action.payload;
        },
        onSubmitReview: (state) => {
            state.isSubmittedReview = true;
        },
        cachedImage: (state, action) => {
            const data = action.payload
            if (data.key && data.base64String) {
                state.cachedImages = {
                    ...state.cachedImages,
                    [data.key]: data.base64String
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.pending, (state, action) => {
                state.isLoading = true;
                state.state = "UploadPhoto";
                state.apiStatus = "uploading";
                state.apiError = null;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.apiStatus = "teeth whitening";
                const { file_path, outputpath } = action.payload;
                state.filePath = file_path;
                if (!outputpath) {
                    state.apiError = "It looks like you could smile better, please recapture another image.";
                    state.apiStatus = "failed";
                } else {
                    state.outputPath = outputpath;
                    state.fullOutputPath = API_URL + outputpath;
                    state.apiError = null;
                }

                state.isUploaded = true;
                state.isLoading = false;
                state.uploadPercent = 100; // Ensure progress is set to 100% when done
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.apiStatus = "failed";
                state.apiError = action.payload || "It looks like you could smile better, please recapture another image.";
                state.isLoading = false;
            })
            .addCase(regenerate.pending, (state, action) => {
                state.isLoading = true;
                const { meta } = action;
                if (meta && meta.arg) {
                    if (meta.arg.endPoint === "teeth_whitening") {
                        state.apiStatus = "teeth whitening";
                        state.teethWhitening = null;
                        state.fullTeethWhitening = null;
                    } else {
                        state.apiStatus = "regenerating";
                        state.outputPath = null;
                        state.fullOutputPath = null;
                    }
                }

                state.state = "Regenerate";
                state.apiError = null;
                state.isLoading = false;
            })
            .addCase(regenerate.fulfilled, (state, action) => {
                state.apiStatus = "";
                const { output_path, endPoint } = action.payload;
                if (output_path) {
                    if (endPoint === "teeth_whitening") {
                        state.teethWhitening = output_path;
                        state.fullTeethWhitening = API_URL + output_path;
                    } else {
                        state.outputPath = output_path;
                        state.fullOutputPath = API_URL + output_path;
                    }
                    state.apiStatus = "";
                } else {
                    state.apiError = "It looks like you could smile better, please recapture another image.";
                }
                state.state = "ResultPhoto";
                state.isLoading = false;
                // else {
                //     state.apiError = "It looks like you could smile better, please recapture another image.";
                //     state.apiStatus = "failed";
                // }
            })
            .addCase(regenerate.rejected, (state, action) => {
                state.apiStatus = "failed";
                state.apiError = action.payload || "It looks like you could smile better, please recapture another image.";
                state.isLoading = false;
            })
            .addCase(startInterval.fulfilled, (state, action) => {
                state.isRunning = true;
                if (state.intervalId) {
                    clearInterval(state.intervalId);
                    state.intervalId = null;
                }
                state.intervalId = action.payload;
            })
            .addCase(stopInterval.fulfilled, (state, action) => {
                state.isRunning = false;
                state.intervalId = null;
                state.processingPercent = 0;
            })
            .addCase(generatePdf.pending, (state, action) => {
                state.isLoading = true;
                state.pdfDownloading = true;
                state.state = "ResultPhoto";
                state.apiStatus = "downloading";
                state.apiError = null;
            })
            .addCase(generatePdf.fulfilled, (state, action) => {
                const { pdf_path } = action.payload;
                state.isLoading = false;
                state.pdfDownloading = false;
                state.apiError = null;
                if (pdf_path) {
                    state.pdfFullUrl = API_URL + pdf_path;
                    state.pdfUrl = pdf_path;
                }
            })
            .addCase(generatePdf.rejected, (state, action) => {
                state.apiStatus = "failed";
                state.apiError = action.payload;
                state.isLoading = false;
                state.pdfDownloading = false;
            });
    },
});

export default sim.reducer;
export const {
    clearImages,
    addImages,
    createFullScanS3Folder,
    clearFullScanfolder,
    startUploading,
    updatePercent,
    updateProcessingPercent,
    gotoDemoScreen,
    retakePhoto,
    openReview,
    closeReview,
    changeRating,
    changeFeedback,
    onSubmitReview,
    cachedImage
} = sim.actions;
