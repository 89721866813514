
import React, { useEffect } from 'react'
import { Star } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { changeRating, changeFeedback, closeReview, submitReview } from '../../Store/sim';

import Modal from './Modal';

export const ReviewPopup = ({ isOpen = false }) => {
    const dispatch = useDispatch();
    const { rating = 0, feedback = "", isSubmittedReview = false, isShowReview = false } = useSelector(state => state.sim);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);


    const onClose = () => {
        dispatch(closeReview())
    }

    const handleSubmit = () => {
        dispatch(submitReview({ rating, feedback }))
    };



    return <Modal isOpen={isOpen} handleClose={onClose}>
        <div className="py-4">
            {!isSubmittedReview ?
                <>
                    <h2 className="text-2xl font-bold mb-4 text-center">Give us your feedback</h2>
                    <p className="text-gray-600 mb-6 text-center">
                        we'd love to hear your thoughts on your smile transformation.
                    </p>
                    <div className="flex justify-center space-x-1 mb-4">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <Star
                                key={star}
                                className={`h-8 w-8 cursor-pointer ${star <= rating ? 'fill-yellow-400 text-yellow-400' : 'text-gray-300'
                                    }`}
                                onClick={() => dispatch(changeRating(star))}
                            />
                        ))}
                    </div>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded-md mb-4"
                        rows={4}
                        placeholder="Tell us what you think (optional)"
                        value={feedback}
                        onChange={(e) => dispatch(changeFeedback(e.target.value))}
                    />
                    <div className="flex justify-end space-x-2">
                        <button
                            className="px-4 py-2 rounded-md font-red-hat bg-slate-300 font-bold text-gray-950"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className={`px-4 py-2 text-white universal-button rounded-md ${!rating ? 'cursor-not-allowed': ''}`}
                            onClick={handleSubmit}
                            disabled={!rating}
                        >
                            Submit Review
                        </button>
                    </div> </> : <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
                    <p className="text-gray-600 mb-6">
                        We appreciate your feedback. It helps us improve our app and provide a better experience for all users.
                    </p>
                    <button
                        className="px-4 py-2 rounded-md font-red-hat bg-slate-300 font-bold text-gray-950"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            }
        </div>
    </Modal>
}