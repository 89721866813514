import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { getCroppedImg } from "../../Utils/cropImageHelper";
import "./index.css";
import Whiten from "../Whiten";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loader from "../../assets/Loader/loaderAnimation.json";
import ReshootPopup from "./reshoot";
import { imageUpload } from "../../Services/APIs/scan";
import dataURItoBlob from "../../Utils/dataUriToBlob";
import { CircularProgress, Box, Typography } from "@mui/material"; // Import for CircularProgress

const Preview = (props) => {
  const { image, setCamera, onClose, setDemo } = props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [reshoot, setReshoot] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [cropStatus, setCropStatus] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const navigate = useNavigate();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showFullPreview, setShowFullPreview] = useState(false); // New state for full-screen preview
  const [croppedBlob, setCroppedBlob] = useState();
  const [progress, setProgress] = useState(10); // Start at 10%

  // Function to simulate loading progress
  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            return 100;
          }
          return Math.min(oldProgress + 10, 100); // Increase progress by 10% every 1000ms
        });
      }, 2000);
    }
  }, [isLoading]);

  // Get cropped image
  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImgBlob = await getCroppedImg(image, croppedAreaPixels); // Pass croppedAreaPixels to the helper function
      const fileUrl = URL.createObjectURL(croppedImgBlob);
      setCroppedBlob(croppedImgBlob);
      setCroppedImage(fileUrl);
      setCropStatus(true);
      setShowFullPreview(true); // Show full-screen preview
    } catch (error) {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  }, [image, croppedAreaPixels]);

  useEffect(() => {
    showCroppedImage();
  }, []);

  const submit = async () => {
    setLoading(true);
    const file = new File([croppedBlob], "report_image.jpeg", {
      type: "image/jpeg",
    });
    const formData = new FormData();
    formData.append("report_image", file);
    setLoading(true);
    setReshoot(false);
    const folderName = JSON.parse(localStorage.getItem("usertpi")).usertpi;

    await imageUpload({ formData, folderName })
      .then((res) => {
        localStorage.setItem("imageName", res.data.data.imageName);
        navigate("/whiten-track");
      })
      .catch((err) => {
        setReshoot(true);
        setLoading(false);
      });
  };

  const closeReshoot = () => {
    setReshoot(false);
  };

  const onRescan = () => {
    setCamera(true);
  };

  return (
    <>
      {isSuccess ? (
        <Whiten isFullScan={false} />
      ) : (
        <div className="h-full">
          <div>
            <div className=" flex flex-col h-full">
              <p className="text-center my-3  text-lg font-urbanist w-full sm600:text-2xl">
                Preview
              </p>
              <div className="flex-grow flex justify-center items-center overflow-hidden my-5 rounded-3xl mb-44">
                <img src={croppedImage} alt="Cropped Preview" className=" w-4/5 h-4/5 max-w-full max-h-full rounded-3xl" />
              </div>
              <div className="absolute bottom-10 mb-5 w-full flex flex-col justify-center items-center h-10 ">
                <button
                  className="w-80 mt-2 rounded-lg universal-button text-white font-red-hat font-bold text-base py-3 px-2 shadow-2xl sm600:text-2xl"
                  onClick={() => {
                    submit();
                  }}
                  disabled={isLoading}
                >
                  Submit
                </button>
                <button
                  className="w-80 mt-4 rounded-lg font-red-hat bg-slate-300 font-bold text-gray-950 py-3 px-4 shadow-2xl sm600:text-2xl"
                  onClick={() => {
                    setCamera(true);
                  }}
                >
                  Rescan
                </button>
              </div>

            </div>
          </div>

          {isLoading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center  z-50">
              <Box
                sx={{ position: "relative", display: "inline-flex" }}
                className="progress-container"
              >
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={150}
                  sx={{ color: "#C6F277" }} // Custom color for the loading bar

                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color={"048AA8"}
                    className="progress-text"
                    fontSize={22}
                  >{`${progress}%`}</Typography>
                </Box>
              </Box>
            </div>
          )}

          {reshoot && (
            <div>
              <ReshootPopup
                onRescan={onRescan}
                closeReshoot={closeReshoot}
                setDemo={setDemo}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Preview;
