import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addImages } from "../../Store/sim";
import { isTablet, isIOS } from "react-device-detect";
export const TakePhoto = ({ onPhotoSelect }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      alert("Please select an image file");
      return;
    }

    console.log("file name", file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === "string") {
        onPhotoSelect(result);
        dispatch(addImages(result));
      }
    };
    reader.readAsDataURL(file);
  };
  const handleTakePhotoClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="hidden"
        aria-label="Select photo"
        capture={isTablet && !isIOS ? "user" : undefined} // if tablet needed open camera directly
      />
      <button
        type="button"
        className="text-base font-red-hat h-12 rounded-lg  w-full  text-white universal-button sm600:text-2xl"
        onClick={handleTakePhotoClick}
      >
        Take Picture
      </button>
    </div>
  );
};
