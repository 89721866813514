import React, { useState, useEffect } from 'react'

const Modal = ({ isOpen, handleClose, children }) => {
  const [showModal, setShowModal] = useState(isOpen);

  const handleModalClose = () => {
    if (handleClose) {
      handleClose(false);
    }
    setShowModal(false);
  };

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  if (!showModal) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10">
      <div className="bg-white rounded-lg max-w-xs w-full overflow-hidden shadow-xl">
        <div className="relative p-4">
          <button
            onClick={handleModalClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          >
            <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span className="sr-only">Close</span>
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;