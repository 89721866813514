import { configureStore } from "@reduxjs/toolkit";
import fullScanReducer from "./fullScan";
import reportsReducer from "./reports"
import simReducer from "./sim"


export const store = configureStore({
    reducer: {
        fullScan: fullScanReducer,
        reports: reportsReducer,
        sim: simReducer
    }
})

export default store