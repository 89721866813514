'use client'

import React, { useState, useEffect } from 'react'

const defaultColors = [
    'text-black',
    // 'text-red-500',
    // 'text-blue-500',
    'text-green-500',
    // 'text-yellow-500',
    // 'text-purple-500',
    // 'text-pink-500',
    // 'text-indigo-500',
    // 'text-orange-500',
]

export const BlinkText = ({ rootClassName = "w-full", childClassName = "text-base", text = 'Give us feedback', colors = defaultColors, onClick = () => null }) => {
    const [colorIdx, setColorIdx] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (colors.length) {
                setColorIdx((pres) => {
                    if (pres !== (colors.length - 1)) {
                        return pres + 1
                    }
                    return 0
                });
            }
        }, 700) // Blink every 700ms

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isValidHexColor = (color) => {
        // Regular expression for 3-digit or 6-digit hex color codes with an optional '#'
        const hexColorRegex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
        return hexColorRegex.test(color);
    }

    return (
        <div className={`flex justify-center items-center ${rootClassName}`}>
            {/* transition-colors duration-300 */}
            <p
                className={`font-bold text-center ${!isValidHexColor(colors[colorIdx]) ? colors[colorIdx] : ''} ${childClassName}`}
                {...(isValidHexColor(colors[colorIdx]) && {
                    style: { color: colors[colorIdx] },
                })}
                onClick={onClick}
            >
                {text}
            </p>
        </div>
    )
}

