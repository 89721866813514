import React from "react";

import demoImage from "../../assets/DemoImages/demoImage2.png";

import { TakePhoto } from "./TakePhoto";

const DemoScreen = (props) => {
  return (
    <>
      <div className="relative bg-white w-full flex flex-col h-full">
        <div className=" h-1/2 w-full pt-4 px-4 bg-slate-100 ">
          <img
            src={demoImage}
            alt="ai-teeth"
            className="h-full w-full object-contain"
          />
        </div>
        <div className="h-1/2 bg-slate-100 py-6">
          <div className="w-full flex flex-col justify-around px-10">
            <div className=" font-urbanist font-semibold text-lg  text-center px-10 sm600:text-3xl">
               Position your face on the phone screen as shown in the guide above
            </div>
            <div className="pt-6" >
                <TakePhoto onPhotoSelect={(photo) => null} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoScreen;
