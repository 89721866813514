import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import arrowBack from "../../assets/Icons/back-arrow-2.png"
import { clearImages, regenerate, generatePdf, openReview } from '../../Store/sim';
import { Box, Slider } from "@mui/material";
import Dropdown from './Dropdown';
import { BlinkText } from './BlinkText';

// arrow-back.jpg
export function ResultPhoto({ image }) {
  const takenPhoto = useSelector(state => state.sim.takenPhoto);
  const outputPath = useSelector(state => state.sim.outputPath);
  const fullOutputPath = useSelector(state => state.sim.fullOutputPath);
  const filePath = useSelector(state => state.sim.filePath);
  const isLoading = useSelector(state => state.sim.isLoading);
  const pdfDownloading = useSelector(state => state.sim.pdfDownloading);
  const teethWhitening = useSelector(state => state.sim.teethWhitening)
  const fullTeethWhitening = useSelector(state => state.sim.fullTeethWhitening)
  const cachedImages = useSelector(state => state.sim.cachedImages)
  const dispatch = useDispatch();
  // const [isButtons, setIsButtons] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [value, setValue] = useState(0);
  const [images, setImages] = useState({
    0: takenPhoto,
    1: fullOutputPath,
    2: fullTeethWhitening
  })

  useEffect(() => {

    if(cachedImages){
      const tempImg = {
        0: takenPhoto,
        1: fullOutputPath,
        2: fullTeethWhitening
      }
      if(cachedImages.takenPhoto){
        tempImg[0] = cachedImages.takenPhoto;
      }
      if(cachedImages.fullOutputPath){
        tempImg[1] = cachedImages.fullOutputPath;
      }
      if(cachedImages.fullTeethWhitening){
        tempImg[2] = cachedImages.fullTeethWhitening;
      }
      setImages(tempImg)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedImages])


  const marks = [
    { value: 0, label: "0" },
    { value: 1, label: "Smile correction" },
    { value: 2, label: "Whitening" },
  ];

  // const images = {
  //   0: takenPhoto,
  //   1: fullOutputPath,
  //   2: fullTeethWhitening
  // }

  const handleChange = (event, value) => {
    setValue(value); // Update the slider value
    setSliderValue(value)
  };

  const handleRegenerate = (reason) => {
    dispatch(regenerate({ reason, filePath }))
  }

  const downloadPdf = () => {
    dispatch(generatePdf({
      filePaths: [
        filePath,
        outputPath,
        teethWhitening
      ]
    }))
  }

  const handleOpenReview = () => {
    dispatch(openReview())
  }

  useEffect(() => {
    setTimeout(() => {
      handleOpenReview()
    }, 15000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="font-medium w-full h-full flex flex-col">
      <div className="relative flex-grow flex flex-col items-center justify-center m-4 rounded-lg relative overflow-hidden">
        <button
          className="rounded-full bg-gray-100 m-2 w-9 h-9 absolute top-1 left-1"
          onClick={() => dispatch(clearImages())}
        >
          <img src={arrowBack} alt="Back" className="h-8 ml-2 p-1 rounded-full" />
        </button>
        {/* Display the fetched image based on the slider value */}
        {images[sliderValue] ? <>
          {/* <div className="h-60 w-full pt-4 px-4 bg-slate-100 ">
          <img
            src={images[sliderValue]}
            alt="ai-teeth"
            className="h-full w-full object-contain"
          />
        </div> */}
          <img
            src={images[sliderValue]}
            alt={`output`}
            className={"max-h-[22rem] h-4/5 w-auto object-contain rounded-lg "}
          />
        </> :
          <div className='flex items-stretch'>
            <div className='font-bold h-80 text-center rounded-lg py-24 px-2 text-lg'>
              {"We couldn’t recognise the necessary smile area for whitening, please recapture another image."}
            </div>
          </div>}
      </div>
      <div className="flex flex-col px-7 pb-5">
        <div className="flex items-center ">
          <Box className="slider-container ">
            <Slider
              value={value}
              onChange={handleChange}
              min={0}
              max={2} // Adjust max to length of images array
              step={1}
              marks={marks.map((mark) => ({
                value: mark.value,
                label: mark.label,
              }))}
              valueLabelDisplay="on"
              className="custom-slider"
            />
          </Box>
        </div>
        <p className="text-center text-base font-medium mt-1 mb-2 sm600:text-3xl">
          Adjust Slider for Smile Options
        </p>
        <button
          type="button"
          className="text-base font-semibold h-12 rounded-lg w-full mx-auto text-white universal-button my-1 sm600:text-xl"
          onClick={downloadPdf}
        >
          Download {pdfDownloading ? '...' : ''}
        </button>
        <Dropdown
          buttonText='Regenerate'
          btnClasses="w-full mx-auto rounded-lg font-red-hat bg-slate-300 font-bold text-gray-950 py-3  px-4 shadow-2xl sm600:text-2xl"
          items={['Misaligned Teeth', 'Missing Teeth']}
          onItemClick={(item) => {
            const tempObj = {
              "Misaligned Teeth": "misaligned",
              "Missing Teeth": "missing_teeth"
              // 'Gaps in teeth': 'gap_generated',
              // 'Excessive gum area': 'gums_generated',
              // 'Size of teeth': 'teeth_enlarged'
            }
            handleRegenerate(tempObj[item])
          }}
        />
        <BlinkText rootClassName="w-full" childClassName="text-sm" onClick={handleOpenReview} />
        <p className="text-center my-2 text-xs opacity-60 sm600:text-lg">
          AI-generated results for informational purposes only. Outcomes may
          vary. Consult a professional for personalized advice.
        </p>
      </div>
    </div>
    // <div className="flex flex-col h-screen bg-gray p-1">
    //   <div className="flex justify-between items-center mb-4">
    //     <button className="p-2 rounded-full bg-white shadow-sm">
    //         <img src={arrowBack} alt="back buttton" className="w-12 h-12" onClick={() => dispatch(clearImages())} />
    //     </button>
    //     <h1 className="text-2xl font-bold text-cyan-500">tint</h1>
    //     <div className="w-10" /> {/* Spacer for alignment */}
    //   </div>

    //   <div className="flex-grow flex flex-col items-center justify-between">
    //     <div className="relative w-full aspect-square overflow-hidden mb-4  h-3/5 bg-white ">
    //       <img
    //         src={outputPath || takenPhoto}
    //         alt="Portrait of a person smiling"
    //         className='h-full w-full object-contain rounded-lg  '
    //       />
    //       <div className="absolute bottom-4 left-4 bg-white rounded-full px-3 py-1 text-sm font-semibold">
    //         {sliderValue.toString().padStart(2, '0')}
    //       </div>
    //     </div>

    //     <div className="w-full max-w-md mb-4">
    //       <input
    //         type="range"
    //         min="1"
    //         max="3"
    //         value={sliderValue}
    //         onChange={(e) => setSliderValue(parseInt(e.target.value))}
    //         className="w-full appearance-none bg-gray-300 h-1 rounded-full outline-none"
    //         style={{
    //           background: `linear-gradient(to right, #0891b2 0%, #0891b2 ${(sliderValue - 1) * 50}%, #e5e7eb ${(sliderValue -1 ) * 50}%, #e5e7eb 100%)`,
    //         }}
    //       />
    //       <div className="flex justify-between text-sm text-gray-600 mt-2">
    //         {[...Array(3)].map((_, i) => (
    //           <span key={i}>{i + 1}</span>
    //         ))}
    //       </div>
    //     </div>

    //     <div className="text-center mb-4">
    //       <h2 className="text-xl font-semibold mb-2">Adjust Slider for</h2>
    //       <h2 className="text-xl font-semibold">Smile Options</h2>
    //     </div>

    //     <button className="w-full max-w-md bg-teal-700 hover:bg-teal-800 text-white py-2 rounded-full text-lg font-semibold mb-4">
    //       Next
    //     </button>

    //     <p className="text-center text-sm text-gray-500 max-w-md">
    //       AI-generated results for informational purposes only. Outcomes may vary. Consult a professional for personalized advice.
    //     </p>
    //   </div>
    // </div>
  )
}