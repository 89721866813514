import React from 'react'
import tinitLogo from "../../assets/emailPage/tint-logo.jpg"
import notFound from "../../assets/NotFound/404.jpg"
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {

  const navigate = useNavigate()

  return (
    <div className='h-full flex flex-col items-center'>
      <div className='flex-grow flex flex-col justify-center items-center font-medium'>
        <img src={tinitLogo} alt="logo" className=' h-8 md:h-12' />
        <img src={notFound} alt="404" className=' h-[180px] my-5 md:h-56' />
        <p className=' text-xl text-[#022022] font-urbanist md:text-3xl '> Look’s like you are lost! </p>
        <p className=' text-gray-400 text-sm mt-2 font-red-hat md:text-xl'>
          We can’t seem to find the page you’re looking for.
        </p>
      </div>

      <button
        type='button'
        className='universal-button text-white h-12 md:h-16 w-4/5 rounded-lg text-base md:text-xl font-semibold my-5 font-red-hat'
        onClick={() => { navigate("/") }} >
        Back to Home
      </button>

    </div>
  )
}

export default PageNotFound